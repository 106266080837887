import axios from 'axios';
import {AsYouType} from 'libphonenumber-js'
import 'url-search-params-polyfill';
import {Profile} from "classes/profile.class";
import ReactGA from "react-ga";
import {AppState} from "store/reducers";
import {AuthReducerActionType} from "store/reducers/auth.reducer";

type AxiosError = import("axios").AxiosError;

const urlParams = new URLSearchParams(window.location.search);

export const signIn = (uid: string, password: string) => async (dispatch: any, getState: () => AppState) => {
    ReactGA.event({
        category: "Authentication",
        action: "Login attempt"
    });
    try {
        const res = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/login/uid_pw',
            {
                login_token: getState().config.login_token, uid, password
            }
        );
        ReactGA.event({
            category: "Authentication",
            action: "Login success"
        });

        window.location.href = res.data.redirect_to;
    } catch (error) {
        ReactGA.event({
            category: "Authentication",
            action: "Login failed"
        });
        return dispatch({
            type: AuthReducerActionType.SIGNIN_ERROR,
            error_message: 'Väärä sähköpostiosoite tai salasana.'
        })
    }
};

export const forgotPassword = (email: string) => async (dispatch: any, getState: () => AppState) => {
    ReactGA.event({
        category: "Authentication",
        action: "Password recovery attempt"
    });
    try {
        await axios.post(process.env.REACT_APP_API_ENDPOINT + '/account/initiate_password_reset', {
            context_token: getState().config.login_token,
            email,
        })
        ReactGA.event({
            category: "Authentication",
            action: "Password recovery success"
        });
        return dispatch({
            type: AuthReducerActionType.FORGOT_PASSWORD_SUCCESS,
            alert: "Sähköpostiisi on lähetetty palautuslinkki!"
        })
    } catch (error) {
        ReactGA.event({
            category: "Authentication",
            action: "Password recovery failed"
        });
        return dispatch({
            type: AuthReducerActionType.FORGOT_PASSWORD_ERROR,
            error_message: 'Virhe tapahtui palauttaessa salasanaa.'
        })
    }
};

export const forgotUuaPassword = (phone: string, uuaId: string) => async (dispatch: any, getState: () => AppState) => {
    ReactGA.event({
        category: "Authentication",
        action: "UUA password recovery attempt"
    });
    try {
        await axios.post(process.env.REACT_APP_API_ENDPOINT + '/account/direct_password_reset', {
            context_token: getState().config.login_token,
            phone,
            external_id: uuaId,
            type: 'external_id+phone'
        });
        ReactGA.event({
            category: "Authentication",
            action: "UUA password recovery success"
        });
        return dispatch({
            type: AuthReducerActionType.FORGOT_PASSWORD_SUCCESS,
            alert: "Puhelimeesi on lähetetty uusi salasanasi!"
        })
    } catch (error) {
        ReactGA.event({
            category: "Authentication",
            action: "UUA password recovery failed"
        });
        return dispatch({
            type: AuthReducerActionType.FORGOT_PASSWORD_ERROR,
            error_message: 'Virhe tapahtui palauttaessa salasanaa.'
        })
    }
};

export const resetPassword = (password: string, confirmed_password: string) => async (dispatch: any) => {
    ReactGA.event({
        category: "Authentication",
        action: "Password reset attempt"
    });
    if (password !== confirmed_password) {
        ReactGA.event({
            category: "Authentication",
            action: "Password reset failed"
        });
        return dispatch({
            type: AuthReducerActionType.RESET_PASSWORD_ERROR,
            error_message: 'Salasanat eivät täsmää.'
        });
    }
    try {
        await axios.post(process.env.REACT_APP_API_ENDPOINT + '/account/do_password_reset', {
            password_reset_token: urlParams.get('token'),
            new_password: password,
        });
        ReactGA.event({
            category: "Authentication",
            action: "Password reset success"
        });
        dispatch({type: AuthReducerActionType.RESET_PASSWORD_SUCCESS})
    } catch (error) {
        ReactGA.event({
            category: "Authentication",
            action: "Password reset failed"
        });
        return dispatch({
            type: AuthReducerActionType.RESET_PASSWORD_ERROR,
            error_message: 'Virhe tapahtui vaihtaessa salasanaa.'
        })
    }
};

export const signInWithGoogle = (token: string) => async (dispatch: any, getState: () => AppState) => {
    ReactGA.event({
        category: "Authentication",
        action: "Authenticating with Google"
    });
    try {
        const res = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/login/google', {
            login_token: getState().config.login_token,
            some_service: 'google',
            some_token: token,
        })
        ReactGA.event({
            category: "Authentication",
            action: "Login with Google success"
        });
        localStorage.clear();
        window.location.href = res.data.redirect_to;
    } catch (error) {
        console.log(error)
        ReactGA.event({
            category: "Authentication",
            action: "Login with Google failed"
        });
        return dispatch({
            type: AuthReducerActionType.SIGNIN_ERROR,
            error_message: 'Kirjautuminen ei onnistunut.'
        })
    }
};

export const signInWithFacebook = (token: string) => async (dispatch: any, getState: () => AppState) => {
    ReactGA.event({
        category: "Authentication",
        action: "Authenticating with Facebook"
    });
    try {
        const res = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/login/facebook', {
            login_token: getState().config.login_token,
            some_service: 'facebook',
            some_token: token,
        });
        ReactGA.event({
            category: "Authentication",
            action: "Login with Facebook success"
        });
        localStorage.clear();
        window.location.href = res.data.redirect_to;
    } catch (error) {
        ReactGA.event({
            category: "Authentication",
            action: "Login with Facebook failed"
        });
        return dispatch({
            type: AuthReducerActionType.SIGNIN_ERROR,
            error_message: 'Kirjautuminen epäonnistui.'
        })
    }
};


// TODO: Microsoft login
export const signInWithMicrosoft = (data: any) => {
    ReactGA.event({
        category: "Authentication",
        action: "Authenticating with Microsoft"
    });
    return console.log(data)
};

export const abortSignIn = () => async (dispatch: any, getState: () => AppState) => {
    ReactGA.event({
        category: "Authentication",
        action: "Aborting login"
    });
    try {
        const res = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/login/cancel', {
            login_challenge: getState().config.login_challenge,
        });
        localStorage.clear();
        if (!res.data.redirect_to) {
            window.close();
            return;
        }
        window.location.href = res.data.redirect_to;
    } catch (error) {
        console.log(error)
    }
};

export const signUp = (profile: Profile) => async (dispatch: any, getState: () => AppState) => {
    ReactGA.event({
        category: "Authentication",
        action: "Sign up attempt"
    });

    let phoneNumber = null;

    if (profile.phone) {
        let typedPhone = new AsYouType("FI");
        typedPhone.input(profile.phone);
        phoneNumber = typedPhone.getNumber();
        if (!phoneNumber || !phoneNumber.isValid()) {
            ReactGA.event({
                category: "Authentication",
                action: "Sign up failed"
            });
            return dispatch({
                type: AuthReducerActionType.SIGNUP_ERROR,
                error_message: "Puhelinnumerosi on virheellinen!"
            })
        }
    }

    try {
        const res = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/account', {
            account: {
                first_name: profile.first_name,
                last_name: profile.last_name,
                email: profile.email,
                phone: phoneNumber && phoneNumber.number,
                address: {
                    street_address: profile.street_address,
                    city: profile.city,
                    postal_code: profile.postal_code,
                    country_code: profile.country_code
                },
            },
            password: profile.password,
            context_token: getState().config.login_token,
        });
        if (res.data.success) {
            ReactGA.event({
                category: "Authentication",
                action: "Sign up success"
            });
            localStorage.setItem('next_step', res.data.next_step);
            return dispatch({
                type: AuthReducerActionType.SIGNUP_SUCCESS,
                next_step: res.data.next_step,
                validation_tokens: res.data.validation_tokens
            });
        }
    } catch (error: unknown | AxiosError) {
        ReactGA.event({
            category: "Authentication",
            action: "Sign up failed"
        });
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 409) {
                return dispatch({
                    type: AuthReducerActionType.SIGNUP_ERROR,
                    error_message: "Antamasi sähköposti tai puhelinnumero on jo käytössä!",
                    show_reset_link: true,
                });
            }
            return dispatch({
                type: AuthReducerActionType.SIGNUP_ERROR,
                error_message: error.message
            });
        }
        else {
            console.error(error);
        }
    }
};

const error = (res: any, dispatch: any) => {
    if (res.statusCode === 422) {
        return dispatch({
            type: AuthReducerActionType.CONFIRMATION_ERROR,
            error_message: "Vahvistuskoodi on virheellinen!"
        })
    } else if (res.statusCode === 400) {
        return dispatch({
            type: AuthReducerActionType.CONFIRMATION_ERROR,
            error_message: "Vahvistuskoodin pituus on 6 merkkiä!"
        })
    } else {
        return dispatch({
            type: AuthReducerActionType.CONFIRMATION_ERROR,
            error_message: "Tuntematon virhe tapahtui! (Virhekoodi: " + res.statusCode + ")"
        })
    }
};

export const validatePhone = (code: string) => async (dispatch: any, getState: () => AppState) => {
    ReactGA.event({
        category: "Authentication",
        action: "Phone validating attempt"
    });
    try {
        const res = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/account/validate_phone', {
            token: getState().auth.validation_tokens.phone_validation || urlParams.get('token') || JSON.parse(localStorage.getItem("validation_tokens") || "{}").phone_validation,
            code
        });
        if (res.data.success) {
            ReactGA.event({
                category: "Authentication",
                action: "Phone validating success"
            });
            dispatch(nextStep(res.data.next_step));
            if (res.data.validation_tokens) {
                dispatch({type: AuthReducerActionType.APPLY_VALIDATION_TOKENS, validation_tokens: res.data.validation_tokens})
            }
        }
    } catch (err: unknown | AxiosError) {
        ReactGA.event({
            category: "Authentication",
            action: "Phone validating failed"
        });

        if (axios.isAxiosError(err) && err.response) {
            const res = err.response.data;
            return error(res, dispatch)
        }
        else {
            console.error(err);
        }
    }
};

export const validateEmail = (code: string) => async (dispatch: any, getState: () => AppState) => {
    ReactGA.event({
        category: "Authentication",
        action: "Email validating attempt"
    });

    try {
        const res = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/account/validate_email', {
            token: getState().auth.validation_tokens.email_validation || urlParams.get('token') || JSON.parse(localStorage.getItem("validation_tokens") || "{}").email_validation,
            code
        });
        if (res.data.success) {
            ReactGA.event({
                category: "Authentication",
                action: "Email validating success"
            });
            dispatch(nextStep(res.data.next_step));
            if (res.data.validation_tokens) {
                dispatch({type: AuthReducerActionType.APPLY_VALIDATION_TOKENS, validation_tokens: res.data.validation_tokens})
            }
        }
    } catch (err: unknown | AxiosError) {
        ReactGA.event({
            category: "Authentication",
            action: "Email validating failed"
        });

        if (axios.isAxiosError(err) && err.response) {
            const res = err.response.data;
            return error(res, dispatch)
        }
        else {
            console.error(err);
        }
    }
};

export const consent = () => async (dispatch: any) => {
    ReactGA.event({
        category: "Authentication",
        action: "Consent Redirect"
    });
    try {
        const res = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/consent/give', {consent_challenge: urlParams.get('consent_challenge')});
        localStorage.clear();
        window.location.href = res.data.redirect_to;
    } catch (error) {
        dispatch({
            type: AuthReducerActionType.CONSENT_ERROR,
            error_message: 'Jokin meni vikaan'
        })
    }
};

export const nextStep = (next_step: string) => (dispatch: any) => {
    if (next_step === 'done') {
        localStorage.removeItem('next_step');
        localStorage.removeItem('validation_codes');
    } else {
        localStorage.setItem('next_step', next_step);
    }
    dispatch({type: AuthReducerActionType.NEXT_STEP, next_step});
};
